import { CourseResponseType } from "../api/types";
import { DropDownListItems } from "../components/DropDownList";
import { TagsTypes } from "../components/Tag";

export type CourseModuleType = {
    title: string,
    subtitle?: string,
    tagStatus?: TagsTypes,
    items: Array<DropDownListItems>
}

export type CourseType = {
    image?: string,
    title: string,
    rating?: number,
    reviewsCount?: number,
    description: string,
    items: Array<DropDownListItems>,
    id: string,
    isBuyed: string;
    price?: number,
    modules: Array<CourseModuleType>
    paymentId?: string,
    paymentUrl?: string,
    paymentStatus?: string,
    xrid?: string,
}

export default function DetailCourseNormalize(props: CourseResponseType): CourseType {
    const {
        image,
        title,
        description,
        modules,
        payment,
        price,
        id
    } = props;

    const items: Array<DropDownListItems> = [];

    const innerModules = modules.sort((a, b) => a.position - b.position).map((module) => {
        return {
            title: module.title,
            items: module.lessons.sort((a, b) => a.position - b.position).map((lesson) => {
                if(lesson.free) {
                    items.push({
                        ...lesson,
                        isDisabled: false,
                        complete: false,
                        path: `lesson/${id}`
                    })
                }
                return ({
                    title: lesson.title,
                    path: `lesson/${id}`,
                    isDisabled: !lesson.free,
                    id: lesson.id,
                })
            })
        }
    })

    return {
        image,
        title,
        description,
        items,
        id,
        isBuyed: payment.status,
        price,
        modules: innerModules,
        paymentId: payment.payment_id,
    }
}