import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LOADER, ARROWLEFT } from "../../icons";
import { useAppStore } from "../useAppStore";
import { useCurtainControl } from "../useCurtainContol";

import cn from 'classnames'

import style from './index.module.css'

export const useCurtain = (id: string) => {
    const {course, buyCourse, cancelCourseBuying} = useAppStore();
    const navigate = useNavigate();

    const {
        setProps: setCurtainProps
    } = useCurtainControl();


    const showContent = useCallback((content: JSX.Element) => {
        setCurtainProps({
            isOpen: true,
            closeBlock: false,
            children: content
        })
    }, [setCurtainProps])

    const successCurtain = useMemo(() => (
        <div className={style.curtain}>
            <div onClick={() => {setCurtainProps({isOpen: false})}} className={style.closeButton}></div>
            <div className={style.curtainSuccess}></div>
            <div className={style.curtainTitle}>Курс оплачен</div>
            <div className={style.curtainSubtitle}>Ваш новый курс уже ждет вас. Смело переходите к обучению!</div>
            <div onClick={() => {navigate(`/myCourse/${course?.id}`);}} className={style.button}>Перейти к курсу</div>
        </div>
    ), [course, setCurtainProps, navigate])

    const rejectCurtain = useMemo(() => (
        <div className={style.curtain}>
            <div onClick={() => {setCurtainProps({isOpen: false})}} className={style.closeButton}></div>
            <div className={style.curtainReject}></div>
            <div className={style.curtainTitle}>Оплата не удалась</div>
            <div className={style.curtainSubtitle}>Попробуйте еще раз или свяжитесь с поддержкой, если в момент оплаты произошла ошибка</div>
            <div onClick={() => {
                if(course?.id) {
                    buyCourse(course?.id)
                }
            }} className={style.button}>Попробовать еще раз</div>
        </div>
    ), [course, setCurtainProps, buyCourse])

    const errorCurtain = useCallback((xrid: string) => (
        <div className={style.curtain}>
            <div onClick={() => {setCurtainProps({isOpen: false})}} className={style.closeButton}></div>
            <div className={style.curtainReject}></div>
            <div className={style.curtainTitle}>Ошибка оплаты</div>
            <div className={cn(style.curtainSubtitle, style.smallMargin)}>Что-то пошло не так в момент зачисления средств. Обратитесь в поддержку, приложив скриншот этого экрана</div>
            <div className={style.curtainSubtitle}>x-rid: {xrid}</div>
            <div onClick={() => {
                if(course?.id) {
                    buyCourse(course?.id)
                }
            }} className={style.button}>Попробовать еще раз</div>
        </div>
    ), [course, setCurtainProps, buyCourse])

    const loadingCurtain = useMemo(() => (
        <div className={style.curtain}>
            <div onClick={() => {setCurtainProps({isOpen: false})}} className={style.closeButton}></div>
            <div className={style.curtainLoader}><LOADER /></div>
            <div className={style.curtainTitle}>Обработка платежа</div>
            <div className={style.curtainSubtitle}>Ждем подтверждения от платежного сервиса. Это может занять пару минут.</div>
            <div className={style.curtainBottomContent}>
                <a href={course?.paymentUrl} rel="noreferrer" target='_blank' className={style.button}>Открыть экран оплаты</a>
                <div className={style.rejectButton} onClick={() => {showContent(loadingRejectCurtain)}}></div>
            </div>
        </div>
    // eslint-disable-next-line
    ), [course, setCurtainProps])

    const loadingRejectCurtain = useMemo(() => (
        <div className={style.curtain}>
            <div onClick={() => {setCurtainProps({isOpen: false})}} className={style.closeButton}></div>
            <div className={style.curtainLoader}><LOADER /></div>
            <div className={style.curtainTitle}>Отменить оплату?</div>
            <div className={style.curtainSubtitle}>В случае отмены, мы не сможем предоставить вам доступ к курсу</div>
            <div className={style.curtainBottomContent}>
                <div className={style.backToLoadingButtom} onClick={() => {showContent(loadingCurtain)}}><ARROWLEFT fill={'white'} /></div>
                <div onClick={() => {cancelCourseBuying(id)}} className={style.button}>Отменить</div>
            </div>
        </div>
    // eslint-disable-next-line
    ), [course, setCurtainProps])

    return {showContent, successCurtain, rejectCurtain, errorCurtain, loadingCurtain, loadingRejectCurtain}
}