import style from './index.module.css'
import { useAppStore } from '../../hooks/useAppStore'
import DropDownList from '../../components/DropDownList';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useBottonContent } from '../../hooks/useBottomContent';
import { useNavigate } from 'react-router-dom';
import { useCurtainControl } from '../../hooks/useCurtainContol';
import { useCurtain } from '../../hooks/useCurtain';
import { LOADER } from '../../icons';


const DetailCourseContent = () => {
    const {course, buyCourse} = useAppStore();
    const { setProps } = useBottonContent();
    const navigate = useNavigate();

    const {
        setProps: setCurtainProps
    } = useCurtainControl();

    const {showContent, successCurtain, rejectCurtain, errorCurtain, loadingCurtain} = useCurtain(course?.id || '');

    useEffect(() => {
        if(course) {
            if(course.isBuyed === 'BOUGHT') {
                setProps({
                    callback: () => {
                        navigate(`/myCourse/${course.id}`);
                    },
                    buttonProps: {
                        title: 'Перейти к курсу',
                        backgroundColor: 'rgba(183, 136, 229, 1)',
                    },
                    isOpen: true,
                })
                if(course.paymentStatus === 'SUCCESS') {
                    showContent(successCurtain);
                }
            } else if(course.isBuyed === 'IN_PROGRESS') {
                showContent(loadingCurtain); 
                setProps({
                    callback: () => showContent(loadingCurtain),
                    buttonProps: {
                        title: 'Обработка платежа',
                        titleColor: 'rgba(209, 209, 209, 1)',
                        subtitle: <div className={style.loader}><LOADER /></div>,
                        backgroundColor: 'rgba(121, 93, 149, 1)',
                    },
                    isOpen: true,
                })
                if(course.paymentId && !course.paymentUrl) {
                    buyCourse(course.id, true)
                }
            } else {
                switch(course.paymentStatus) {
                    case 'SUCCESS':
                        showContent(successCurtain);
                        setProps({
                            callback: () => {
                                navigate(`/myCourse/${course.id}`);
                            },
                            buttonProps: {
                                title: 'Перейти к курсу',
                                backgroundColor: 'rgba(183, 136, 229, 1)',
                            },
                            isOpen: true,
                        })
                        break;
                    case 'PENDING':
                        showContent(loadingCurtain);
                        setProps({
                            callback: () => showContent(loadingCurtain),
                            buttonProps: {
                                title: 'Обработка платежа',
                                titleColor: 'rgba(209, 209, 209, 1)',
                                subtitle: <div className={style.loader}><LOADER /></div>,
                                backgroundColor: 'rgba(121, 93, 149, 1)',
                            },
                            isOpen: true,
                        })
                        break;
                    case 'FAILED':
                        showContent(rejectCurtain);
                        setProps({
                            callback: () => {
                                buyCourse(course.id)
                            },
                            buttonProps: {
                                title: 'Купить курс',
                                subtitle: `${course.price} ₽`,
                                backgroundColor: 'rgba(183, 136, 229, 1)',
                            },
                            isOpen: true,
                        })
                        break;
                    case 'ERROR':
                        showContent(errorCurtain(course?.xrid || ''))
                        break;
                    default: 
                        setProps({
                            callback: () => {
                                buyCourse(course.id)
                            },
                            buttonProps: {
                                title: 'Купить курс',
                                subtitle: `${course.price} ₽`,
                                backgroundColor: 'rgba(183, 136, 229, 1)',
                            },
                            isOpen: true,
                        })

                }
            }
        }
        return () => {
            setProps({
                isOpen: false,
            })
            setCurtainProps({
                isOpen: false,
            })
        }
    // eslint-disable-next-line
    }, [course])

    return(
        <div className={style.wrapper}>
            {
                course && (
                    <>
                        <div className={style.title}>{course.title}</div>
                        <div className={style.modulesContainer}>
                            {
                                course.modules.map((module: any, index: number) => (
                                    <div key={module.id} className={style.module}>
                                        <DropDownList
                                            title={module.title}
                                            startedItemsCount={index === 0 ? module.items.length : 0}
                                            items={module.items}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            }
            
        </div>
    )
}

export default observer(DetailCourseContent)