import { SVGIcon } from ".";

export default function ARROWLEFT(props: SVGIcon) {
    const {fill} = props;

    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.21505 1.52319C8.504 1.26634 8.53003 0.823893 8.27319 0.534945C8.01634 0.245997 7.57389 0.219971 7.28495 0.476813L8.21505 1.52319ZM0.534945 6.47681C0.245997 6.73366 0.219971 7.17611 0.476813 7.46505C0.733656 7.754 1.17611 7.78003 1.46505 7.52319L0.534945 6.47681ZM1.46505 6.47683C1.17611 6.21999 0.733656 6.24601 0.476813 6.53496C0.219971 6.82391 0.245997 7.26636 0.534945 7.5232L1.46505 6.47683ZM7.28495 13.5232C7.57389 13.78 8.01634 13.754 8.27319 13.4651C8.53003 13.1761 8.504 12.7337 8.21505 12.4768L7.28495 13.5232ZM1 6.29999C0.613401 6.29999 0.3 6.61339 0.3 6.99999C0.3 7.38659 0.613401 7.69999 1 7.69999V6.29999ZM19 7.69999C19.3866 7.69999 19.7 7.38659 19.7 6.99999C19.7 6.61339 19.3866 6.29999 19 6.29999V7.69999ZM7.28495 0.476813L0.534945 6.47681L1.46505 7.52319L8.21505 1.52319L7.28495 0.476813ZM0.534945 7.5232L7.28495 13.5232L8.21505 12.4768L1.46505 6.47683L0.534945 7.5232ZM1 7.69999H19V6.29999H1V7.69999Z" fill={fill}/>
        </svg>
    )
}

